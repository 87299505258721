var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultCrudView',{attrs:{"breadcrumbsItems":_vm.breadcrumbs,"actions":_vm.actions,"containerLarge":""},on:{"create-quota":_vm.newQuota}},[_c('template',{slot:"content"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tenantQuotas,"loading":_vm.loading,"options":_vm.options,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.last_credit_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.last_credit_date).toLocaleString("pt-BR", { dateStyle: "short" })))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editQuota(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit_quota")))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.createCredit(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-cash-plus ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("new_credit")))])])]}}])}),_c('DefaultModal',{ref:"quotaModal",attrs:{"title":_vm.getModalTitle()},on:{"submit":function($event){return _vm.saveQuota()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DynamicFormContent',{attrs:{"fields":_vm.quotaFields}})]},proxy:true}])}),_c('DefaultModal',{ref:"creditModal",attrs:{"title":_vm.$t('new_credit')},on:{"submit":function($event){return _vm.saveCredit()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-text-field',{attrs:{"label":_vm.$t('value'),"rules":[
            function (v) { return !!v || _vm.$t('form_rules.mandatory'); },
            function (v) { return Number.isInteger(Number(v)) || _vm.$t('form_rules.number'); }
          ],"outlined":"","dense":""},model:{value:(_vm.credit.value),callback:function ($$v) {_vm.$set(_vm.credit, "value", $$v)},expression:"credit.value"}}),_c('v-text-field',{attrs:{"label":_vm.$t('event'),"rules":[function (v) { return !!v || _vm.$t('form_rules.mandatory'); }],"outlined":"","dense":""},model:{value:(_vm.credit.event),callback:function ($$v) {_vm.$set(_vm.credit, "event", $$v)},expression:"credit.event"}})]},proxy:true}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }