<template>
  <DefaultCrudView
    @create-quota="newQuota"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    containerLarge
  >
    <template slot="content">
      <v-data-table
        :headers="headers"
        :items="tenantQuotas"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        hide-default-footer
      >
        <template v-slot:item.last_credit_date="{ item }">
          <span>{{ new Date(item.last_credit_date).toLocaleString("pt-BR", { dateStyle: "short" }) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editQuota(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t("edit_quota") }}</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="createCredit(item)"
              >
                mdi-cash-plus
              </v-icon>
            </template>
            <span>{{ $t("new_credit") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <DefaultModal
        :title="getModalTitle()"
        ref="quotaModal"
        @submit="saveQuota()"
      >
        <template v-slot:content>
          <DynamicFormContent :fields="quotaFields"></DynamicFormContent>
        </template>
      </DefaultModal>

      <DefaultModal
        :title="$t('new_credit')"
        ref="creditModal"
        @submit="saveCredit()"
      >
        <template v-slot:content>
          <v-text-field
            :label="$t('value')"
            v-model="credit.value"
            :rules="[
              (v) => !!v || $t('form_rules.mandatory'),
              (v) => Number.isInteger(Number(v)) || $t('form_rules.number')
            ]"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            :label="$t('event')"
            v-model="credit.event"
            :rules="[(v) => !!v || $t('form_rules.mandatory')]"
            outlined
            dense
          ></v-text-field>
        </template>
      </DefaultModal>
    </template>
  </DefaultCrudView>
</template>

<style scoped>
.container {
  padding: 0;
}

.table-container {
  margin-top: 20px;
}
</style>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import TenantActions from '@/store/core/tenant/actions-types'
import TenantMutations from '@/store/core/tenant/mutations-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "TenantQuotas",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent
  },

  data() {
    return {
      loading: true,
      tenantQuotas: [],
      tenantId: 0,
      credit: {},
      options: {},
      actions: [
        {
          text: "Create Quota",
          eventToEmit: "create-quota"
        }
      ],
      headers: [
        {text: 'Service', value: 'service'},
        {text: 'Balance', value: 'balance'},
        {text: 'Balance limit', value: 'balance_limit'},
        {text: 'Monthly credit', value: 'monthly_credit'},
        {text: 'Billing type', value: 'type_of_billing'},
        {text: 'Last credit', value: 'last_credit_date'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
    }
  },

  computed: {
    ...mapGetters('app', [
      'getIconForCoreSettingsItem'
    ]),

    ...mapState({
      selectedTenant: state => state.core.tenant.selectedTenant,
      selectedQuota: state => state.core.tenant.selectedQuota,
      quotaFields: state => state.core.tenant.quotaFields,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('tenants'),
          prependIcon: this.getIconForCoreSettingsItem('tenants'),
          iconColor: 'primary',
          to: {
            name: "Tenant"
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: `${this.selectedTenant ? this.selectedTenant.title : ''} - ${this.$t('quotas')}`,
          disabled: true,
        }
      ]
    }
  },

  mounted() {

    this.loading = true
    this.tenantId = this.$route.params.tenantId

    this.[TenantActions.GET_TENANT](this.tenantId)
      .then((tenant) => {
        this.[TenantMutations.SET_SELECTED_TENANT](tenant)
        this.reloadData()
      })
  },

  methods: {
    ...mapActions('core/tenant', [
      TenantActions.GET_TENANT,
      TenantActions.LIST_TENANT_QUOTAS,
      TenantActions.SAVE_TENANT_QUOTA,
      TenantActions.ADD_CREDIT,
    ]),
    
    ...mapMutations('core/tenant', [
      TenantMutations.SET_SELECTED_TENANT,
      TenantMutations.SET_SELECTED_TENANT_QUOTA,
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE
    ]),

    reloadData() {
      let options = {}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      this.loading = true
      this.credit = {}

      this.[TenantActions.LIST_TENANT_QUOTAS](this.selectedTenant.tenant_id)
        .then((result) => {
          this.tenantQuotas = [...result]
          this.loading = false
      })
    },

    getModalTitle() {
      return this.selectedQuota ? this.$t('edit_quota') : this.$t('create_quota')
    },

    newQuota() {
      this.[TenantMutations.SET_SELECTED_TENANT_QUOTA](null)
      this.$refs.quotaModal.openModal()
    },

    editQuota(quota) {
      this.[TenantMutations.SET_SELECTED_TENANT_QUOTA](quota)
      this.$refs.quotaModal.openModal()
    },

    saveQuota() {
      this.$refs.quotaModal.errorMessage = null
      this.$refs.quotaModal.errorList = []

      this.[TenantActions.SAVE_TENANT_QUOTA](this.selectedTenant.tenant_id)
        .then(() => {
          let message = this.selectedQuota ? 'quota_edited' : 'quota_created'
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))
          this.$refs.quotaModal.closeModal()
          this.reloadData()
        })
        .catch((err) => {
          this.$refs.quotaModal.submitting = false
          this.[AppActions.OPEN_APP_ERROR_MESSAGE](err.message)
        })
    },

    createCredit(quota) {
      this.[TenantMutations.SET_SELECTED_TENANT_QUOTA](quota)
      this.$refs.creditModal.openModal()
    },

    saveCredit() {
      this.[TenantActions.ADD_CREDIT](this.credit)
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('credit_added'))
          this.$refs.creditModal.closeModal()
          this.reloadData()
        })
        .catch(() => { this.$refs.creditModal.submitting = false })
    }
  }
}

</script>
